import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Icon from '../Icon'
import { h4Style } from '../../styleElements'
import useHover from '../../hooks/useHover'

const Button = ({
  external,
  to,
  type,
  icon,
  activeIcon,
  children,
  ...props
}) => {
  const { isHover, handleMouseOver, handleMouseOut } = useHover()

  const RootButton = getRootButton(type)
  const buttonFrame = () => (
    <RootButton
      {...props}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Label>{children}</Label>
      {icon && !isHover && <ButtonIcon size={30} name={icon} />}
      {icon && isHover && <ButtonIcon size={30} name={activeIcon} />}
    </RootButton>
  )

  if (external && to) {
    return (
      <ButtonExternalLink href={to} target="_blank" rel="noopener noreferrer">
        {buttonFrame()}
      </ButtonExternalLink>
    )
  }

  if (to) {
    return <ButtonInternalLink to={to}>{buttonFrame()}</ButtonInternalLink>
  }

  return buttonFrame()
}

Button.propTypes = {
  to: PropTypes.string,
  type: PropTypes.oneOf(['basic', 'outlineDark', 'outlineLight', 'disabled']),
  external: PropTypes.bool,
  icon: PropTypes.string,
  activeIcon: PropTypes.string
}

Button.defaultProps = {
  to: '',
  type: 'basic',
  external: false,
  icon: '',
  activeIcon: ''
}

const getRootButton = type => {
  switch (type) {
    case 'outlineDark':
      return OutlineDarkButton
    case 'outlineLight':
      return OutlineLightButton
    case 'disabled':
      return DisabledButton
    default:
      return BasicButton
  }
}

const basicStyle = css`
  min-width: 140px;
  height: 43px;
  padding: 0 10px 0 15px;
  border-radius: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  transition: all 0.3s ease-in;
  cursor: pointer;
`

const Label = styled.p`
  ${h4Style};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  line-height: 0;
  transition: all 0.3s ease-in;
`

const BasicButton = styled.button`
  ${basicStyle}
  border-color: ${props => props.theme.color.primary1};
  background-color: ${props => props.theme.color.primary1};

  ${Label} {
    color: ${props => props.theme.color.white};
  }

  &:hover {
    border-color: ${props => props.theme.color.primary2};
    background-color: ${props => props.theme.color.primary2};
  }
`

const OutlineDarkButton = styled.button`
  ${basicStyle}
  border-color: ${props => props.theme.color.primary1};
  background-color: ${props => props.theme.color.transparent};

  ${Label} {
    color: ${props => props.theme.color.primary1};
  }

   &:hover {
    border-color: ${props => props.theme.color.primary2};
    background-color: ${props => props.theme.color.primary2};

    ${Label} {
      color: ${props => props.theme.color.white};
    }
  }
`

const OutlineLightButton = styled.button`
  ${basicStyle}
  border-color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.transparent};

  ${Label} {
    color: ${props => props.theme.color.white};
  }

  &:hover {
    border-color: ${props => props.theme.color.white};
    background-color: ${props => props.theme.color.white};

    ${Label} {
      color: ${props => props.theme.color.primary2};
    }
  }
`

const DisabledButton = styled.button`
  ${basicStyle}
  border-color: ${props => props.theme.color.gray3};
  background-color: ${props => props.theme.color.gray3};
  cursor: not-allowed;

  ${Label} {
    color: ${props => props.theme.color.white};
  }
`

const ButtonIcon = styled(Icon)`
  padding-left: 5px;
`

const linkStyle = css`
  display: inline-block;
`

const ButtonInternalLink = styled(Link)`
  ${linkStyle};
`

const ButtonExternalLink = styled.a`
  ${linkStyle};
`

export default Button
