import { useState } from 'react'

const useHover = () => {
  const [isHover, setHover] = useState(false)
  const handleMouseOver = () => setHover(true)
  const handleMouseOut = () => setHover(false)

  return {
    isHover,
    handleMouseOver,
    handleMouseOut
  }
}

export default useHover
